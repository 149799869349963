import React, { useState } from 'react'
import Logo from '../../assets/images/clogow.png'
import LandingCards from './LandingCards';
import About from './About';
import Service from './Service';
import Process from './Process';
import Enquiry from './Enquiry';
import Footer from './Footer';

const Navbar = () => {

    const [Canvas, setCanvas] = useState(false)
    


    //Start of Offcanvas script

    function showHamMenu(){
        setCanvas(true)
    }

    function pageScroll(block){
        setCanvas(false)
        let element = document.getElementById(block)
        
        element.scrollIntoView({behavior: "smooth"})
        console.log('func called:', element)
    }

    const hideOffcanvas = ()=>{
        setCanvas(false)
    }

    //End of Offcanvas script

  return (
    <div className="col-md-12 p-0 home-container" id="home">
        <nav className="navbar navbar-expand-lg py-3" id="navmenu-container">
            <div className="container-fluid d-flex justify-content-between align-items-start">
                <div>
                    <a className="navbar-brand fw-bold fs-3" href="/"><img className="logo" src={Logo} alt="Catchify Technology"/></a>
                </div>
                <div id="navmenu-items">
                    <div className="navbar-collapse">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#about-block">About us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#service-block">Services</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#process-block">Our Process</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#enquiry-block">Contact us</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="get-quote-btn-container">
                    <a href="#enquiry-block" className="btn rounded-pill fs-5 btn-light get-quote-btn">Get Quote</a>
                </div>
                <div id="ham-menu">
                    <i className="bi bi-list ham-menu-icon" onClick={()=>showHamMenu()}></i>
                </div>
            </div>
        </nav>
        <div className="landing-container">
            <div className="landing-content">
                <h1 className="landing-content-title">Welcome to Catchify - Where Digital Excellence Begins</h1>
                <p className="landing-content-desc">We blend website development, UI/UX design, SEO, and app innovation to elevate your online presence. Our dedicated team ensures performance and user engagement, offering cutting-edge solutions for your digital success.</p>
                <button className="landing-content-button" onClick={()=>pageScroll('service-block')}>Our Services</button>
                <div className="feature-cards-container">
                    <div className="feature-cards">

                    </div>
                </div>
            </div>
        </div>

        {/* Offcanvas start, block which enables on hamurger-menu */}
        {Canvas && <div className="menu-sm-container" id="menu-sm">
            <div className="ham-menu-sm-container" style={{height:'100vh'}}>
                <div className="menu-sm-header">
                    <img className="logo" src={Logo} alt="Catchify Technology"/>
                    <i className="bi bi-x-circle text-light menu-sm-closebtn" onClick={()=>hideOffcanvas()}></i>
                </div>
                <div className="menu-sm-body">
                    <div className="list-group text-center">
                        {/* aria-current="true" className="active" */}
                        <p onClick={()=>pageScroll('home')} className="list-group-item list-group-item-action">Home</p>
                        <p onClick={()=>pageScroll('about-block')} className="list-group-item list-group-item-action">About us</p>
                        <p onClick={()=>pageScroll('service-block')} className="list-group-item list-group-item-action">Services</p>
                        <p onClick={()=>pageScroll('process-block')} className="list-group-item list-group-item-action">Our Process</p>
                        <p onClick={()=>pageScroll('enquiry-block')} className="list-group-item list-group-item-action">Contact us</p>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button onClick={()=>pageScroll('enquiry-block')} className="sm-getQuotebtn">Get Quote</button>
                    </div>
                </div>
            </div>
        </div>}
        {/* Offcanvas end */}

        <div className="col-md-12 pt-2 pb-5 landing-card-block">
            <LandingCards/>
        </div>
        <div class="col-md-12 pt-0 pb-5" id="about-block">
            <About/>
        </div>
        <div class="col-md-12 pt-0 pb-5" id="service-block">
            <Service/>
        </div>
        <div class="col-md-12 pt-0 pb-5" id="process-block">
            <Process/>
        </div>
        <div class="col-md-12 pb-5" id="enquiry-block">
            <Enquiry/>
        </div>
        <div class="col-md-12 p-0 footer-block" id="footer-block">
            <Footer/>
        </div>
    </div>
  )
}

export default Navbar
