import React, { useRef, useState } from 'react'
import CIllus from '../../assets/images/contact illus - Copy.png'
import emailjs from '@emailjs/browser';

const Enquiry = () => {
    
    const nameRef = useRef()
    const emailRef = useRef()
    const contactRef = useRef()
    const companyRef = useRef()
    const cityRef = useRef()
    const subjectRef = useRef()
    const descRef = useRef()
    const formRef = useRef()

    const [EnqStatus, setEnqStatus] = useState(null)
    const [EnqLoader, setEnqLoader] = useState(false)


    const SendEmail = (e)=> {
        setEnqLoader(true)
        e.preventDefault()

        const name = nameRef.current.value
        const email = emailRef.current.value
        const contact = contactRef.current.value
        const company = companyRef.current.value
        const city = cityRef.current.value
        const subject = subjectRef.current.value
        const desc = descRef.current.value


        if(name&&email&&contact&&city&&subject){
            emailjs.sendForm('service_1iecn5e', 'template_xza0mzs', formRef.current, 'h2r3ITvvNQJO1Tyyd')
            .then(() => {
                setEnqLoader(false)
                setEnqStatus(true)
                alert("Email sent successfully!")
                formRef.current.reset();
                setTimeout(() => {
                    setEnqStatus(null)
                }, 3000);
            }, (error) => {
                setEnqLoader(false)
                setEnqStatus(false)
                alert(error.text);
                setTimeout(() => {
                    setEnqStatus(null)
                }, 3000);
            });
        }else{
            setEnqLoader(false)
            alert('Please fill all the fields');
        }


    }

  return (
    <div>
        <h2 className="section-name text-light">GET QUOTE</h2>
        <p className="section-desc">Elevate your digital journey with Catchify by filling out our simple form; let us turn your ideas into reality and provide you with a tailored solution that meets your unique needs.</p>
        <div className="enquiry-container">
            <div className="enquiry-illus-container">
                <img className="enquiry-illus" src={CIllus} alt=""/>
            </div>
            <div className="form-container">
                <form className="enquiry-form" ref={formRef} onSubmit={SendEmail}>
                    <div className="mb-2">
                        <label for="exampleInputName" className="form-label fw-bold">Name*</label>
                        <input ref={nameRef} name="fullname" required type="text" className="form-control" placeholder="Full name" id="exampleInputName" aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-2">
                        <label for="exampleInputEmail" className="form-label fw-bold">Email address*</label>
                        <input ref={emailRef} name="email" required type="email" className="form-control" placeholder="Email" id="exampleInputEmail" aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-2">
                        <label for="exampleInputContact" className="form-label fw-bold">Contact Number*</label>
                        <input ref={contactRef} name="contact" required type="number" className="form-control" placeholder="Contact Number" id="exampleInputContact" aria-describedby="emailHelp"/>
                    </div>
                    <div className="mb-2">
                        <label for="exampleInputCompany" className="form-label fw-bold">Company Name</label>
                        <input ref={companyRef} name="company" type="text" className="form-control" placeholder="Company" id="exampleInputCompany" aria-describedby="emailHelp"/>
                    </div>
                    <div className="mb-2">
                        <label for="exampleInputCity" className="form-label fw-bold">City*</label>
                        <input ref={cityRef} name="city" required type="text" className="form-control" placeholder="Place" id="exampleInputCity" aria-describedby="emailHelp"/>
                    </div>
                    <div className="mb-2">
                        <label for="exampleInputSubject" className="form-label fw-bold">Subject*</label>
                        <input ref={subjectRef} name="subject" required type="text" className="form-control" placeholder="Subject of the Enquiry" id="exampleInputSubject" aria-describedby="emailHelp"/>
                    </div>
                    <div className="mb-2">
                        <label for="exampleInputDescription" className="form-label fw-bold">Description</label>
                        <textarea ref={descRef} name="description" id="exampleInputDescription" placeholder="Describe the Enquiry" className="form-control" cols="10" rows="5"></textarea>
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <button type="submit" className="btn btn-outline-light fw-bold m-3">Submit detail</button>
                        {   EnqLoader&&
                            <div className="spinner-border text-success m-3" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>}
                    </div>
                </form>
                <div className="form-response-container" id="enquiry-status-container">
                    {(EnqStatus===true)&&<p className='my-3 alert alert-success'>Enquiry send Successfully!</p>}
                    {(EnqStatus===false)&&<p className='my-3 alert alert-danger'>Enquiry not send, Please try again later!</p>}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Enquiry
