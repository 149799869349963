import React from 'react'
import Header from '../site/Header'

const TermsCondition = () => {
  return (
    <div>
        <Header/>
        <div className='p-2'>
            <h1 className='text-center fs-1 my-2'><u>Terms and Conditions</u></h1>
            <p>These Terms and Conditions govern your use of our website and services. By accessing our website and using our services, you agree to these terms.</p>
        
            <dl>
                <dt>Services</dt>
                <dd>
                    <ul>
                        <li><strong>Provision:</strong> Catchify offers various IT services, including web development, app development, UI/UX designing, SEO, digital marketing, and content writing.</li>
                        <li><strong>Hackathons:</strong> We conduct hackathon programs for college students, which require registration and payment of fees.</li>
                    </ul>
                </dd>
                
                <dt>Payments</dt>
                <dd>
                    <ul>
                        <li><strong>Non-Refundable:</strong> All hackathon registration fees and advance payments for services are non-refundable unless specified otherwise.</li>
                        <li><strong>Methods:</strong> Payments can be made through the preferred payment modes provided on our website.</li>
                    </ul>
                </dd>
                
                <dt>Use of Website</dt>
                <dd>
                    <ul>
                        <li><strong>Content:</strong> The content on our website is for informational purposes only. We reserve the right to modify or remove content at any time.</li>
                        <li><strong>Conduct:</strong> Users must not engage in any activity that disrupts or interferes with our website's functionality.</li>
                    </ul>
                </dd>
                
                <dt>Liability</dt>
                <dd>
                    <ul>
                        <li><strong>Disclaimer:</strong> We do not guarantee the accuracy or completeness of the information on our website. We are not liable for any errors or omissions.</li>
                        <li><strong>Limitation:</strong> Our liability is limited to the maximum extent permitted by law. We are not liable for any indirect, incidental, or consequential damages.</li>
                    </ul>
                </dd>
                
                <dt>Changes to Terms</dt>
                <dd>We may update these Terms and Conditions periodically. Changes will be posted on our website, and your continued use of our services constitutes acceptance of the revised terms.</dd>
                
                <dt>Contact Us</dt>
                <dd>For any questions or concerns regarding these Terms and Conditions, please contact us at <a href="mailto:catchify.group@gmail.com">catchify.group@gmail.com</a> or +91 94425 32369.</dd>
            </dl>
        </div>
    </div>
  )
}

export default TermsCondition
