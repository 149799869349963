import React from 'react'
import { Routes, Route } from 'react-router-dom'
import SiteHome from './Home'

const SiteRoutes = () => {
  return (
    <div>
        <Routes>
          <Route path='/' element={<SiteHome/>} />
        </Routes>
    </div>
  )
}

export default SiteRoutes
