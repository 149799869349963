import React from 'react'
import '../../css/App.css'

const Footer = () => {
  return (
    <div className="footer-container">
        <div className="footer-link-container">
            <div className="quick-link-container ms-2">
                <p className="fw-bold">Quick Links</p>
                <div className="quick-link">
                    <a className="dropdown-item" href="/">Home</a>
                    <a className="dropdown-item" href="#about-block">About Us</a>
                    <a className="dropdown-item" href="#service-block">Services</a>
                    <a className="dropdown-item" href="#process-block">Our Process</a>
                    <a className="dropdown-item" href="#enquiry-block">Contact us</a>
                </div>
            </div>
            <div className="social-icon-container">
                <i onClick={()=>window.open('https://www.instagram.com/_catchify_/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==', '_blank')} className="bi bi-instagram" id="insta"></i>
                <i onClick={()=>window.open('https://api.whatsapp.com/send/?phone=+919442532369&text=Hello..,%20I%20have%20visited%20your%20page')} className="bi bi-whatsapp" id="whatsapp"></i>
                <i onClick={()=>window.open('mailto:catchify.group@gmail.com', '_blank')} className="bi bi-envelope-at" id="envelope"></i>
                <i onClick={()=>window.open('tel:+919442532369', '_blank')} className="bi bi-telephone" id="telephone"></i>
                <i onClick={()=>window.open('https://www.linkedin.com/in/catchify-group-bb925523b', '_blank')} className="bi bi-linkedin" id="linkedin"></i>
            </div>
        </div>
        <div className="footer-copyright-container">
            <div className='text-center d-flex flex-wrap justify-content-center align-items-center'>
                <a className='link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover m-2' href='/privacy-policy'>Privacy & Policies</a>
                <a className='link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover m-2' href='/terms-conditions'>Terms and Conditions</a>
            </div>
            <p className="text-center m-0 pb-2">Copyright © CATCHIFY, 2022. All rights reserved.</p>
        </div>
    </div>
  )
}

export default Footer
