import React, { useState } from 'react'
import Catchifyimg1 from '../../assets/images/landing_illus-removebg.png'
import Catchifyweb1 from '../../assets/images/website_illus-removebg.png'

const About = () => {

    const [Accordion, setAccordion] = useState('choose')

  return (
    <div className="about-container">
        <h2 className="section-name">ABOUT US</h2>
        <div className="about-info-container">
            <div className="about-info-img-container">
                <img className="about-info-img" src={Catchifyimg1} alt="Catchify Technology"/>
            </div>
            <div className="about-info-desc">
                <p>At <strong>CATCHIFY</strong>, we transcend boundaries and redefine digital excellence. As a dynamic and forward-thinking company, we specialise in a comprehensive suite of services, seamlessly integrating <code>Website development</code>, <code>UI/UX design</code>, and <code>SEO strategies</code>.
                    <br/>We are a team of passionate creatives, developers, and strategists united by a common goal: to elevate your digital presence. Our diverse skill set allows us to craft holistic solutions that go beyond mere aesthetics, ensuring every aspect of your online presence is optimised for performance and user engagement.
                </p>
            </div>
            <div className="about-info-img-container-sm">
                <img className="about-info-img" src={Catchifyimg1} alt="Catchify Technology"/>
            </div>
        </div>
        <div className="about-accordion-container">
            <div className="accordion-container">
                <div className={`accordion ${(Accordion==='choose')&&'active'}`}>
                    <div className="accordion-label" onClick={()=>setAccordion('choose')}>Why choose us?</div>
                    <div className="accordion-content">
                    <p>Choose <strong>Catchify</strong> for cutting-edge innovation, collaborative partnerships focused on your vision, results-driven digital experiences, and unwavering customer-centric commitment. We thrive on emerging technologies, ensuring your digital presence excels.<br/>Your success is paramount – <code>Website development</code> to continuous support, we are dedicated to your excellence.</p>
                    </div>
                </div>
                
                <div className={`accordion ${(Accordion==='mission')&&'active'}`}>
                    <div className="accordion-label" onClick={()=>setAccordion('mission')}>Our Mission</div>
                    <div className="accordion-content">
                    <p>Our mission is simple yet profound – to empower businesses with cutting-edge digital solutions that captivate audiences and drive success.<br/>
                    <strong>Boundaries, Redefining Excellence:</strong> Catchify - Where <code>Website Development, UI/UX Design, and SEO Strategies</code> Unite for Digital Success.</p>
                    </div>
                </div>
                
                <div className={`accordion ${(Accordion==='vision')&&'active'}`}>
                    <div className="accordion-label"  onClick={()=>setAccordion('vision')}>Our Vision</div>
                    <div className="accordion-content">
                    <p>Our vision at <code>Catchify</code> is to be at the forefront of digital innovation, shaping transformative experiences that redefine industry standards. We aspire to seamlessly integrate creativity and technology, empowering businesses with cutting-edge solutions.<br/> With an unwavering commitment to client success, we envision Catchify as the go-to partner for visionary digital journeys, where innovation, collaboration, and results-driven strategies converge to create unparalleled excellence.</p>
                    </div>
                </div>
            </div>
            <div className="accordion-illus-container">
                <img className="accordion-illus" src={Catchifyweb1} alt="Catchify Technology"/>
            </div>
        </div>
    </div>
  )
}

export default About
