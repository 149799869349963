import React from 'react'

const Service = () => {
  return (
    <div class="service-parent-container">
        <h2 class="section-name">PROVIDED SERVICES</h2>
        <p class="section-desc">Catchify redefines digital excellence with dynamic Websites, intuitive UI/UX design, strategic SEO, and comprehensive Digital marketing for a transformative online presence.</p>
        <div class="service-cards-container">
            <div class="service-card">
                <div class="service-card-icon">
                    <i class="bi bi-pc-display-horizontal"></i>
                </div>
                <h3>Website Development</h3>
                <p class="service-card-desc">Crafted for Excellence: Dynamic and responsive websites tailored to your unique needs for an impactful online presence.</p>
            </div>
            <div class="service-card">
                <div class="service-card-icon">
                    <i class="bi bi-window-stack"></i>
                </div>
                <h3>UI/UX Designing</h3>
                <p>Designing Experiences: Intuitive and visually appealing user interfaces that enhance user engagement and satisfaction.</p>
            </div>
            <div class="service-card">
                <div class="service-card-icon">
                    <i class="bi bi-globe2"></i>
                </div>
                <h3>SEO Strategies</h3>
                <p>Elevate Visibility: Strategic optimization to improve search engine visibility, driving organic traffic and maximizing online reach.</p>
            </div>
            <div class="service-card">
                <div class="service-card-icon">
                    <i class="bi bi-file-earmark-bar-graph"></i>
                </div>
                <h3>Digital Marketing</h3>
                <p>Propel Growth: Comprehensive digital marketing strategies to boost your online presence, engage audiences, and drive business growth.</p>
            </div>
        </div>
    </div>
  )
}

export default Service
