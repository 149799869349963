import '../css/App.css';
import { Route, Routes } from 'react-router-dom';
import SiteRoute from './site/SiteRoutes'
import NotFound from './site/NotFound';
import PrivacyPolicy from './policies/PrivacyPolicy';
import TermsCondition from './policies/TermsCondition';


function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<SiteRoute/>} />
        <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
        <Route path='/terms-conditions' element={<TermsCondition/>} />
        <Route path='*' element={<NotFound/>} />
      </Routes>
    </div>
  )
}

export default App;
