import React from 'react'
import Header from '../site/Header'

const PrivacyPolicy = () => {
  return (
    <div>
      <Header/>
      <div className='p-2'>
        <h1 className='text-center fs-1 my-2'><u>Our Policies</u></h1>
        <dl>
            <dt className='fs-2'>Privacy Policy</dt>
            <dd>
                <p>At Catchify, we value the privacy of our users and customers. This Privacy Policy outlines the types of information we collect, how we use it, and the steps we take to ensure your data is protected.</p>
                
                <dt>Information We Collect</dt>
                <dd>
                    <ul>
                        <li><strong>Personal Information:</strong> When you contact us through our website, we collect details such as your Name, Email, City, Company Name, Phone Number, Subject, and Description.</li>
                        <li><strong>Hackathon Registration:</strong> For hackathon participants, we collect Student Name, Email, College Name, City, Date of Birth, College, and Department details.</li>
                        <li><strong>Payment Information:</strong> We collect payment details for hackathon registration fees and client payments.</li>
                    </ul>
                </dd>

                <dt>Use of Information</dt>
            <dd>
                <ul>
                    <li><strong>Service Provision:</strong> Your information is used to provide our services, respond to inquiries, and process payments.</li>
                    <li><strong>Communication:</strong> We may use your contact information to send updates, promotional materials, and other relevant information.</li>
                    <li><strong>Improvement:</strong> Information collected helps us improve our services and website functionality.</li>
                </ul>
            </dd>
            
            <dt>Data Protection</dt>
            <dd>
                <ul>
                    <li><strong>Security:</strong> We implement industry-standard security measures to protect your data from unauthorized access, alteration, disclosure, or destruction.</li>
                    <li><strong>Access:</strong> Only authorized personnel have access to personal data, and they are required to maintain its confidentiality.</li>
                </ul>
            </dd>
            
            <dt>Your Rights</dt>
            <dd>
                <ul>
                    <li><strong>Access and Correction:</strong> You have the right to access and correct your personal information.</li>
                    <li><strong>Opt-Out:</strong> You can opt out of receiving promotional communications from us.</li>
                </ul>
            </dd>

            </dd>
        
    
        
            
            
            
            
            
            <dt className='fs-2'>Return & Refund Policy</dt>
            <dd>
                <ul>
                    <li><strong>Non-Refundable Payments:</strong>
                        <ul>
                            <li>Hackathon Registration Fees: All hackathon registration fees are non-refundable unless the payment was made in error or deemed irrelevant.</li>
                            <li>Business Advance Payments: All advance payments made for business services are non-refundable unless the payment was made in error or deemed irrelevant.</li>
                        </ul>
                    </li>
                    <li><strong>Eligibility for Refund:</strong> Payments made in error or not related to our services may be eligible for a refund upon verification.</li>
                    <li><strong>Refund Process:</strong>
                        <ul>
                            <li>Request: To request a refund, contact us at <a href="mailto:catchify.group@gmail.com">catchify.group@gmail.com</a> with your payment details and reason for the refund.</li>
                            <li>Verification: We will verify the payment and reason for the refund.</li>
                            <li>Processing: Approved refunds will be processed within 7-10 business days.</li>
                        </ul>
                    </li>
                </ul>
            </dd>
            
            <dt className='fs-2'>Shipment & Delivery Policy</dt>
            <dd>
                <ul>
                    <li><strong>Service Delivery:</strong>
                        <ul>
                            <li>Digital Services: Most of our services, such as web development and digital marketing, are delivered digitally. Once the service is completed, we will notify you via email or through the preferred communication method.</li>
                            <li>Project Timeline: Delivery timelines vary based on project requirements and complexity. We will provide an estimated delivery date upon project initiation.</li>
                        </ul>
                    </li>
                    <li><strong>Physical Shipment:</strong>
                        <ul>
                            <li>Products: If any physical products are shipped, we will use reliable courier services to ensure safe delivery.</li>
                            <li>Tracking: Shipment tracking information will be provided to you once the product is dispatched.</li>
                        </ul>
                    </li>
                    <li><strong>Delivery Issues:</strong>
                        <ul>
                            <li>Delays: In case of any delays, we will notify you promptly and provide a revised delivery schedule.</li>
                            <li>Damaged Products: If you receive a damaged product, please contact us immediately for assistance.</li>
                        </ul>
                    </li>
                </ul>
            </dd>

            <dt className='fs-2'>Changes to Policy</dt>
            <dd>We may update this Privacy Policy periodically. Changes will be posted on our website, and your continued use of our services constitutes acceptance of the revised policy.</dd>
        

            <dt className='fs-2'>Contact Us</dt>
            <dd>For any questions or concerns regarding this Privacy Policy, please contact us at <a href="mailto:catchify.group@gmail.com">catchify.group@gmail.com</a> or +91 94425 32369.
            <dt className='fs-5'>Address</dt>
            <dd>No 07, Thiruveni East, Kalapatti, Coimbatore - 641 048</dd>
            </dd>
        </dl>
      </div>
    </div>
  )
}

export default PrivacyPolicy
