import React from 'react'
import Logo from '../../assets/images/clogow.png'

const Header = () => {

  return (
    <div style={{background: '#081844'}} className='px-3 py-2 d-flex justify-content-between align-items-center'>
        <img className='m-2' src={Logo} style={{height: '50px'}}/>
        <button style={{height: 'fit-content'}} className='btn btn-primary rounded-pill' onClick={()=>window.history.back()}>Go Back <i className='bi bi-x-circle'></i></button>
    </div>
  )
}

export default Header
