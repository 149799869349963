import React from 'react'

const LandingCards = () => {
  return (
    <div className="landing-cards-container">
        <div className="landing-card">
            <div className="landing-card-header">
                <i className="bi bi-cash-coin landing-card-icon"></i>
                <h2 className="landing-card-title">Cost-Effective</h2>
            </div>
            <p className="landing-card-desc">Affordable excellence is our promise at Catchify. We deliver top-notch digital solutions tailored to your budget, ensuring you get the best value for your investment.</p>
        </div>
        <div className="landing-card">
            <div className="landing-card-header">
                <i className="bi bi-hourglass-bottom landing-card-icon"></i>
                <h2 className="landing-card-title">On-Time Delivery</h2>
            </div>
            
            <p className="landing-card-desc">Count on Catchify for timely results. Our dedicated team guarantees on-time delivery, turning your digital vision into reality exactly when you need it.</p>
        </div>
        <div className="landing-card">
            <div className="landing-card-header">
                <i className="bi bi-transparency landing-card-icon"></i>
                <h2 className="landing-card-title">Transparency</h2>
            </div>
            <p className="landing-card-desc">Your vision is our priority at Catchify. We keep you involved in your digital journey. Trust Catchify for a partnership that values your ideas at every step.</p>
        </div>
    </div>
  )
}

export default LandingCards
