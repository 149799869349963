import React from 'react'
import ProcessSM from '../../assets/images/CatProcesssm.svg'
import ProcessLG from '../../assets/images/CatProcess.svg'

const Process = () => {
  return (
    <div class="process-parent-container">
        <h2 class="section-name">ORGANIZATIONAL PROCESS</h2>
        <p class="section-desc">Catchify redefines digital excellence with dynamic websites, intuitive UI/UX design, strategic SEO, and comprehensive digital marketing for a transformative online presence.</p>
        <div class="process-diagram-container">
            <img class="process-diagram-lg" src={ProcessLG} alt="Catchify's Process"/>
            <img class="process-diagram-sm" src={ProcessSM} alt="Catchify's Process"/>
        </div>
    </div>
  )
}

export default Process
